import Image from '../image/Image'
import './map.css'

export default function Map(props) {
  return (
    <div className='map' key='map'>
      <Image selection={ props.selection }
             isMapVisible={ props.isMapVisible }
             data={ props.data }
             onOpenModal={ props.onOpenModal } />
    </div>
  ); 
}