import { useRef } from 'react';
import { LockerDB } from '../base/db/DB';
import Icons from '../constants/Icons';
import { useLiveQuery } from 'dexie-react-hooks';
import './lockerItems.css';

export default function LockerItems(props) {
  const loaded = useRef(false);

  const lockerItems = useLiveQuery(async () => {
    const items = await LockerDB.lockerItems.toArray();
    loaded.current = true;
    return items;
  });

  const lockerGridClass = props.isLockerShown ? props.isLockerExpanded ? 'lockerGrid lockerGridExpanded' : 'lockerGrid lockerGridShown' : null;
  const lockerItemClass = props.isLockerShown ? props.isLockerExpanded ? 'lockerItem lockerItemExpanded' : 'lockerItem lockerItemShown' : null;
  
  return (
    <div className={ lockerGridClass }>
      { lockerItems?.map(lockerItem => (
          <div className='lockerItemTile' key={ lockerItem.key } title={ lockerItem.name }>
            <img className={ lockerItemClass } src={ lockerItem.image } onClick={ e => props.onOpenLockerItem(lockerItem.key) } alt='thumbnail'/>      
            <div className='lockerItemCloseIcon' onClick={ e => props.onRemoveLockerItem(lockerItem.key) }>{ Icons.closeIcon }</div>
          </div>
        ))
      }
    </div>  
  );
}