import React from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useWindowWidth } from '@wojtekmaj/react-hooks';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default function PDF(props) {  
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const width = useWindowWidth();

  return (
    <Document file={ process.env.PUBLIC_URL + '/pdf/' + props.content }>
      <Page pageNumber={ 1 } onRenderSuccess={ props.onRenderSuccess }
            width={ Math.min(width, 'auto') }/>        
    </Document>
  );  
}