import React from 'react';

class Tags extends React.Component {
    static curated_urban_landscapes = 'curated urban landscapes';
    static headquarters = 'headquarters';
    static collaborative = 'collaborative';
    static branding = 'branding';
    static cyberpunk = 'cyberpunk';
    static creative_factories = 'creative factories';
    static centrality = 'centrality';
    static periphery = 'periphery';
    static new_work = 'new work';
    static resistance = 'resistance';
    static sharing = 'sharing';
    static imagination = 'imagination';
    static migration = 'migration';
    static past = 'past';
    static conversion = 'conversion';
    static gentrification = 'gentrification';
    static mobility = 'mobility';
    static speculation = 'speculation';
    static root = 'root';
}

export default Tags;