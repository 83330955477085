import Cities from './cities/Cities';
import Concepts from './concepts/Concepts';
import './footer.css';

export default function Footer(props) {
  const selection = props.selection;
  return (
    props.isMapVisible ? null :
    <div className='footerContainer'>
      <div className='footer'>
        <Concepts selection={ selection }                       
                  onConcept={ (concept, isSelected) => props.onConcept(concept, isSelected) } />
        <Cities selection={ selection }
                onCity={ (city, isSelected) => props.onCity(city, isSelected) } />
      </div>
    </div>
  );
}