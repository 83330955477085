import Node from './Node';
import data from './Data';

let nodes = [];

data.forEach(node => {
  nodes.push(new Node(node.key, 
                      node.group,
                      node.concept,
                      node.city,
                      node.mediaType,
                      0,
                      node.content,
                      node.aspectRatio,
                      node.caption,
                      node.credits,
                      node.name));  
});

export default nodes;