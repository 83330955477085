import { useEffect, useRef } from 'react';
import { ForceGraph3D } from 'react-force-graph';
import SpriteText from 'three-spritetext';
import { useLiveQuery } from 'dexie-react-hooks';
import { VisitedDB } from '../../base/db/DB';
import GraphNode from './GraphNode';
import GraphLink from './GraphLink';
import Tags from '../../constants/Tags';
import Colors from '../../constants/Colors';
import { colorFromConcept } from '../../constants/converter';
import './graph.css';

export default function Graph(props) {

  const { selection, isMapVisible, data } = props;
  
  const isSelected = (city, concept) => selection[0].get(city) && selection[1].get(concept);

  const isTag = (node) => node.group === undefined;

  const isRoot = (node) => node.id === Tags.root;
    
  const onOpenModal = (node) => {
    if (!isTag(node)) {
      props.onOpenModal(node);
    }
  }

  const color = (node) => {
    return colorFromConcept(node.concept, visitedNodeIds?.includes(node.id));
  }

  const nodeVisibility = (node) => {
    if (isMapVisible) {
      if (!isTag(node)) { // on map show all non-tag nodes
        return true;
      }
    }
    else {
      if ((isTag(node) && !isRoot(node)) || isSelected(node.city, node.concept)) { // on text show all non-root tags and all selected non-tag nodes
        return true;
      }
    }
    return false;
  }

  const nodeThreeObject = (node) => {
    if (!isMapVisible && isTag(node) && !isRoot(node)) {
      const sprite = new SpriteText(node.id);
      sprite.color = 'white';
      sprite.textHeight = 12;
      sprite.fontWeight = 'bold';
      return sprite;
    }
    return null;
  }

  /**const nodeCanvasObject = (node, ctx, globalScale) => {    
    if (!isMapVisible && isTag(node) && !isRoot(node)) {
      const fontSize = 12 / globalScale;
      ctx.font=`bold ${fontSize}px Roboto`;
      ctx.fillStyle='white';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(node.id, node.x, node.y);
    };
  }

  const nodeCanvasObjectMode = (node) => {
    if (!isMapVisible && isTag(node) && !isRoot(node)) {
      return 'replace';
    }
  }*/

  const visitedNodeIds = useLiveQuery(async () => {
    const visited = await VisitedDB.visitedNodes.toArray();
    return visited.map(obj => obj.id)
  }, [], []);
  
  const graphData = {
    nodes: data.map(node => new GraphNode(node)),
    links: data.map(link => new GraphLink(link.key, isTag(link) ? Tags.root : link.group, isTag(link) ? 100 : 33))
  };

  const ref3d = useRef();

  /**function onEngineStop() {
    const forceGraph = ref3d.current;
    if (forceGraph) {
      forceGraph.zoomToFit(500);
    }        
  }*/

  useEffect(() => {
    document.querySelector('.scene-tooltip').style.color = isMapVisible ? Colors.black : Colors.white;
    const forceGraph = ref3d.current;
    if (forceGraph) {
      forceGraph.d3Force('link').distance(node => node.distance);
      forceGraph.d3Force('charge').strength(-150);
      // forceGraph.controls().noZoom = true;      
      forceGraph.zoomToFit(500);
    }    
  }, [isMapVisible]);

  return (<ForceGraph3D ref={ ref3d } 
                        graphData={ graphData }
                        onNodeClick={ onOpenModal } 
                        nodeVisibility={ nodeVisibility }
                        nodeOpacity={ 1 }
                        nodeResolution={ 32 }
                        backgroundColor={ Colors.transparent }
                        linkVisibility={ null }
                        nodeColor={ color }
                        // nodeCanvasObject={ nodeCanvasObject }
                        // nodeCanvasObjectMode={ nodeCanvasObjectMode }
                        nodeThreeObject={ nodeThreeObject }
                        // enablePanInteraction={ false }
                        // enableZoomInteraction={ false }
                        // autoPauseRedraw={ true }
                        onNodeDragEnd={node => {
                          node.fx = node.x;
                          node.fy = node.y;
                          node.fz = node.z;
                        }}
                        warmupTicks={ 250 }
                        // onEngineStop={ onEngineStop }
                        cooldownTicks={ 100 } />);  
};