import Cities from '../constants/Cities';
import Concepts from '../constants/Concepts';
import Tags from '../constants/Tags';
import Credits from '../constants/Credits';
import MediaTypes from '../constants/MediaTypes';
import Linkify from 'react-linkify';
import Icons from '../constants/Icons';

let data = [
  {
   'key': Tags.root,    
  }, {
   'key': Tags.branding,
  }, {  
   'key': Tags.centrality,
  }, {  
   'key': Tags.collaborative,
  }, {
   'key': Tags.conversion,
  }, {  
   'key': Tags.creative_factories,
  }, {  
   'key': Tags.curated_urban_landscapes,
  }, {  
   'key': Tags.gentrification,
  }, {  
   'key': Tags.headquarters,
  }, {  
   'key': Tags.imagination,
  }, {  
   'key': Tags.migration,
  }, {
   'key': Tags.mobility,
  }, {  
   'key': Tags.new_work,
  }, {  
   'key': Tags.past,
  }, {  
   'key': Tags.resistance,
  }, {
   'key': Tags.sharing,
  }, {
   'key': Tags.speculation,
  },

  {
   'key': 0,
   'group': Tags.creative_factories,
   'concept': Concepts.governance,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830195154?h=f3741693d6',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'foundry_hub',
   'credits': Credits.gunes012021
  }, {  
   'key': 1,
   'group': Tags.mobility,
   'concept': Concepts.space,
   'city': Cities.poznan,
   'mediaType': MediaTypes.video,
   'content': '829593307?h=429d720acd',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'comuting',
   'credits': Credits.tytus012021
  }, {
   'key': 2,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.governance,
   'city': Cities.london,
   'mediaType': MediaTypes.text,
   'content': '“It is also around the time [2010], when the Californian media company TechCrunch arrived in the area. It tries to bring venture capitalists and IT start-ups together. TechCrunch founder and manager Mike Butcher was also a very prominent figure in Boris Johnson’s smart city planning. He worked quite actively on the whole tech city idea. And he is a very anti-Conservative, anti-Tory, anti-Brexit person - He has been very instrumental in the change of the whole area.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'tech_crunch',
   'credits': Credits.gunes012021
  }, {  
   'key': 3,
   'group': Tags.headquarters,
   'concept': Concepts.commons,
   'city': Cities.berlin,
   'mediaType': MediaTypes.pdf,
   'content': 'Zukin.pdf',
   'aspectRatio': 0,
   'caption': '',
   'name': 'sharon_zukin',
   'credits': Credits.tbd
  }, {
   'key': 4,
   'group': Tags.creative_factories,
   'concept': Concepts.economy,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.video,
   'content': '830193322?h=05443dfe01',
   'aspectRatio': 54.17,
   'caption': '',
   'name': 'google_campus',
   'credits': Credits.kuba122020
  }, {  
   'key': 5,
   'group': Tags.creative_factories,
   'concept': Concepts.economy,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830191351?h=8df45fac3e',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'kings_place',
   'credits': Credits.scott012021
  }, {
   'key': 6,
   'group': Tags.collaborative,
   'concept': Concepts.governance,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.text,
   'content': '“Over the last years, ‘Barcelona digital’ implemented a very clear discourse on free software, open source and eye-level policies that embrace ethical digital standards. That is one of their main focuses of work. It has come up with a network that embraces cities of digital rights. It is investing in digital social innovation. It has a grant program, where social and smaller actors can propose activist projects.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'barcelona_digital',
   'credits': Credits.wouter112020
  }, {  
   'key': 7,
   'group': Tags.collaborative,
   'concept': Concepts.data,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“In Poland, progressive and critical economists do not propose alternatives. Critical thinkers make jokes about Facebook or how to shut down the internet, but their first idea is not to think about alternatives, to change how the world works. Economists say that the platforms exist and will exist, we have to think of how to socialize them, reinvent them, make them more just.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'socialize_platforms',
   'credits': Credits.kuba122020
  }, {  
   'key': 8,
   'group': Tags.collaborative,
   'concept': Concepts.governance,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.text,
   'content': <Linkify>“<a href="https://decidim.barcelona" target="_blank" rel="noreferrer">Decidim{Icons.linkIcon}</a> is part of ‘Barcelona Digital’. The Decidim Community was first of all very much used by the city itself. Already four years ago (2016), the Decidim server was used by 40.000 citizens. In a nutshell, Decidim is an open source community kick-started by the municipality of Barcelona. There is a bottom-up municipal agenda with several municipal action programs organised through the Decidem platform. What is does is basically facilitating the involvement of neighbourhoods and a large community of citizens in participatory processes. You can make proposals, there are assemblies to discuss. You can do consultations, surveys, newsletters, and you can organize conferences as well.”</Linkify>,
   'aspectRatio': 0,
   'caption': '',
   'name': 'decidim',
   'credits': Credits.wouter112020
  }, {  
   'key': 9,
   'group': Tags.collaborative,
   'concept': Concepts.governance,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.text,
   'content': '“Barcelona has tried to seek ways to be not only a corporate smart city before Barcelona en comú took over the government, but in the last five years the city has accelerating more initiatives to empower citizens.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'barcelona_en_comu',
   'credits': Credits.wouter112020
  }, {  
   'key': 10,
   'group': Tags.branding,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.text,
   'content': '“The Foundry was a magnet for subversive tech communities, who were also interested in the more artistic and creative applications. And suddenly, in 2010, it is officially becoming Silicon Roundabout, a branding exercise which ended drawing venture capital into the area.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'silicon_roundabout',
   'credits': Credits.gunes012021
  }, {
   'key': 11,
   'group': Tags.creative_factories,
   'concept': Concepts.economy,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830191012?h=3a96ce2e6d',
   'aspectRatio': 62.5,
   'caption': '',
   'name': 'last_fm',
   'credits': Credits.gunes012021
  }, {  
   'key': 12,
   'group': Tags.conversion,
   'concept': Concepts.space,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.video,
   'content': '830345318?h=daabfc0fb3',
   'aspectRatio': 54.17,
   'caption': '',
   'name': 'sezam',
   'credits': Credits.kuba122020
  }, {  
   'key': 13,
   'group': Tags.conversion,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830347990?h=6a39f800d4',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'gasholder_park',
   'credits': Credits.scott012021
   },
  {
   'key': 14,
   'group': Tags.conversion,
   'concept': Concepts.space,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.image,
   'content': '/img/14/01.jpg',
   'aspectRatio': 0,
   'caption': 'The Coòpolis association is to become one of the largest cooperative incubators in Europe. It is funded by the Barcelona City Council and located in a former squatted factory building.',
   'name': 'coopolis',
   'credits': Credits.tbd
  }, {  
   'key': 15,
   'group': Tags.migration,
   'concept': Concepts.work,
   'city': Cities.poznan,
   'mediaType': MediaTypes.text,
   'content': '“There were many cases of modern slavery enterprises. People from Poland bring people from Ukraine to Poland to work for little or no money, take their documents and keep them enclosed. But I have not heard of those things at Amazon.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'modern_slavery',
   'credits': Credits.kuba122020
  }, {  
   'key': 16,
   'group': Tags.migration,
   'concept': Concepts.economy,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“It is said, that almost one third of the estimated one million migrants from Ukraine are temporary workers. They move back and forth between Poland and Ukraine. People who work in Amazon warehouses are often temporary workers. They do no stay very long, they work for three months and bring the money back to Ukraine.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'temporary_workers',
   'credits': Credits.kuba122020
  }, {  
   'key': 17,
   'group': Tags.migration,
   'concept': Concepts.economy,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“What connects the workers at Amazon and the workers in the game industry is that they are from Ukraine. But they are from different classes and different places. Most people from Ukraine working in the (creative) gaming industry in Warsaw are coming from big cites, like Kiev and Charkiw. And I assume that in Amazon’s logistics centres, people mainly do not come from the urban areas, but from the countryside.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'countryside_logistics_center',
   'credits': Credits.kuba122020
  }, {
   'key': 18,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830355534?h=5892f0f91a',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'coal_drops_yard',
   'credits': Credits.scott012021
  }, {  
   'key': 19,
   'group': Tags.mobility,
   'concept': Concepts.space,
   'city': Cities.poznan,
   'mediaType': MediaTypes.video,
   'content': '829592584?h=f9214db0f4',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'transportation_nodes',
   'credits': Credits.tytus012021
  }, {
   'key': 20,
   'group': Tags.centrality,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830312809?h=647c0cef63',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'knowledge_quarter',
   'credits': Credits.scott012021
  }, {  
   'key': 22,
   'group': Tags.centrality,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830308275?h=cea9a6714f',
   'aspectRatio': 62.5,
   'caption': '',
   'name': 'kings_cross_financial_district',
   'credits': Credits.gunes012021
  }, {  
   'key': 23,
   'group': Tags.branding,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830317995?h=d5f8218ec5',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'new_kings_cross',
   'credits': Credits.scott012021
  }, {  
   'key': 24,
   'group': Tags.sharing,
   'concept': Concepts.governance,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.text,
   'content': '“There are three main departments that have direct involvement with everything that could be considered as smart or sharing economy: the digital department, ‘Barcelona Digital’, the social solidarity economy department and the culture and education department. Each has its own innovation programs.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'sharing_economy',
   'credits': Credits.kuba122020
  }, {  
   'key': 25,
   'group': Tags.creative_factories,
   'concept': Concepts.economy,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“A big part of those start-ups are companies that develop computer games and mobile games. The boom of the gaming industry is another important thing that happened in Poland after 2015. 450 game industry studios are operating in Poland, most of them in Warsaw and Wroclaw. Most of them use these flexible on-demand office spaces.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'start_ups',
   'credits': Credits.kuba122020
  }, {  
   'key': 26,
   'group': Tags.creative_factories,
   'concept': Concepts.economy,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.text,
   'content': '“The culture department has a range of old factories buildings. They renovate them and convert them into what they called “factories of creation”, with co-working spaces and many different innovative programs.“',
   'aspectRatio': 0,
   'caption': '',
   'name': 'renovation',
   'credits': Credits.wouter112020
  }, {  
   'key': 28,
   'group': Tags.creative_factories,
   'concept': Concepts.economy,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.text,
   'content': '“Barcelona has some very great buildings that have been recently renovated. One of them, ‘the Coópolis Cooperative Incubator’, as they call it, is an old factory. There is a co-working space in the ground floor, where commons and collaborative economy projects are welcomed to become part of the innovation community. It is part of a program to set up incubators for the collaborative economy.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'incubator',
   'credits': Credits.wouter112020
  }, {  
   'key': 29,
   'group': Tags.imagination,
   'concept': Concepts.space,
   'city': Cities.berlin,
   'mediaType': MediaTypes.slideshow,
   'content': [ { original: process.env.PUBLIC_URL + '/img/29/01.png' },
                { original: process.env.PUBLIC_URL + '/img/29/02.png' },
                { original: process.env.PUBLIC_URL + '/img/29/03.png' },
                { original: process.env.PUBLIC_URL + '/img/29/04.png' },
                { original: process.env.PUBLIC_URL + '/img/29/05.png' },
                { original: process.env.PUBLIC_URL + '/img/29/06.png' },
                { original: process.env.PUBLIC_URL + '/img/29/07.png' },
                { original: process.env.PUBLIC_URL + '/img/29/08.png' },
                { original: process.env.PUBLIC_URL + '/img/29/09.png' },
                { original: process.env.PUBLIC_URL + '/img/29/10.png' },
                { original: process.env.PUBLIC_URL + '/img/29/11.png' } ],
   'aspectRatio': 0,
   'caption': <Linkify> <a href="https://www.metrozones.info/creative-factories/" target="_blank" rel="noreferrer">Counterfactory in Berlin {Icons.linkIcon}</a> </Linkify>,
   'name': 'counterfactory',
   'credits': Credits.diana
  }, {  
   'key': 30,
   'group': Tags.branding,
   'concept': Concepts.governance,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830314423?h=8b36ce45db', 
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'branding',
   'credits': Credits.scott012021
  }, {  
   'key': 31,
   'group': Tags.imagination,
   'concept': Concepts.space,
   'city': Cities.poznan,
   'mediaType': MediaTypes.text,
   'content': '“Unlike Google, Amazon does not have a very broad vision of a smart city. When Google is trying to built Sidework Labs in Canada, Amazon produces smaller devices that you can use at home, like Alexa. These are always inventory pieces that are not staged to interfere with the structure of the city.“',
   'aspectRatio': 0,
   'caption': '',
   'name': 'invention',
   'credits': Credits.tytus012021
  }, {  
   'key': 32,
   'group': Tags.new_work,
   'concept': Concepts.space,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“After 2015, many new investments are real estate buildings being prepared for renting out to digital companies and start-ups. They are waiting for big tech to come to Warsaw. At the moment we do not have a lot of big tech companies in Warsaw, no big Facebook offices, no Google offices. There is no Amazon tower. But you can find these elastic, creative, start-uppy on-demand office rental services being offered in numerous scales.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'big_tech',
   'credits': Credits.kuba122020
  }, {  
   'key': 33,
   'group': Tags.new_work,
   'concept': Concepts.economy,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“It turns out that Amazon in Warsaw rent from WeWork. WeWork advertises itself as a tech company but what it actually does is simply renting desks on demand, small office space, short or long term.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'we_work',
   'credits': Credits.kuba122020
  }, {  
   'key': 35,
   'group': Tags.branding,
   'concept': Concepts.economy,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.video,
   'content': '830312029?h=8d8b43e85e',
   'aspectRatio': 54.17,
   'caption': '',
   'name': 'computer_games',
   'credits': Credits.kuba122020
  }, {  
   'key': 36,
   'group': Tags.branding,
   'concept': Concepts.economy,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830313696?h=a11cd3f88f',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'kx_quarterly',
   'credits': Credits.scott012021
  }, {  
   'key': 37,
   'group': Tags.resistance,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.text,
   'content': '“English heritage stated at that time (2010) that there was no justification for such tall building of this nature like Art´otel in Shoreditch. That was before all those skyscrapers arrived in that area. So they said: ’We believe that the proposals are harmful for the historical monuments, they do not correspond with national or local conservation legislational guidence.’”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'artotel',
   'credits': Credits.gunes012021
  }, {  
   'key': 38,
   'group': Tags.resistance,
   'concept': Concepts.space,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“Warsaw is a city full of activist movements. But I have not heard a lot about protests against those new office spaces being built. Well, if it is an historical building that has some symbolical cultural significance, it is different. For example, the central department store in Warsaw: there was an urban fight between city council, developers and activists. There is the prominent activist group called ‘Miasto jest nasze’, which means ‘The city is ours’, they are working in Warsaw for more than 10 years.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'the_city_is_ours',
   'credits': Credits.kuba122020
  }, {  
   'key': 39,
   'group': Tags.resistance,
   'concept': Concepts.space,
   'city': Cities.poznan,
   'mediaType': MediaTypes.text,
   'content': '“It is kind of unique that we have such a strong workers union presence in Amazon logistic centers. It is an amazing work that they’re doing. However, in the start-up worlds critical minds are almost missing.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'workers_union',
   'credits': Credits.kuba122020
  }, {  
   'key': 40,
   'group': Tags.resistance,
   'concept': Concepts.space,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.text,
   'content': '“The strongest critique is coming from the far left and the far right. Both are against Western companies. The left is against Western capital, the others are against anyone who is not Polish.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'western_capital',
   'credits': Credits.kuba122020
  }, {
   'key': 41,
   'group': Tags.creative_factories,
   'concept': Concepts.governance,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830194318?h=89330b8c64',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'linchpin_college',
   'credits': Credits.scott012021
  }, {
   'key': 42,
   'group': Tags.past,
   'concept': Concepts.economy,
   'city': Cities.london,
   'mediaType': MediaTypes.text,
   'content': '“The Foundry and the whole area of Shoreditch in that years (between 1999 and 2010) embedded the playful and punkish attitude towards tech for expressive purposes. The tech community there was subversive rather than institutionalised.”',
   'aspectRatio': 0,
   'caption': '',
   'name': 'foundry_shoreditch',
   'credits': Credits.gunes012021
  }, {
   'key': 43,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830344155?h=d173cadcdf',
   'aspectRatio': 62.5,
   'caption': '',
   'name': 'banksy',
   'credits': Credits.gunes012021
  }, {
   'key': 44,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830355013?h=152b815d35',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'food_establishments',
   'credits': Credits.scott012021
  }, {
   'key': 45,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830342712?h=3575cbbeee',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'pancras_square',
   'credits': Credits.gunes012021
  }, {
   'key': 46,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830350488?h=59246708f0',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'german_gymnasium',
   'credits': Credits.scott012021
  }, {
   'key': 47,
   'group': Tags.curated_urban_landscapes,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830340696?h=b668c1a1b2',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'simulacrum',
   'credits': Credits.scott012021
  }, {
   'key': 48,
   'group': Tags.gentrification,
   'concept': Concepts.governance,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '830189260?h=ce46d6d434',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'olympic_games',
   'credits': Credits.gunes012021
  }, {
   'key': 49,
   'group': Tags.headquarters,
   'concept': Concepts.data,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829612686?h=b362e81d5a',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'deep_mind',
   'credits': Credits.scott012021
  }, {
   'key': 50,
   'group': Tags.headquarters,
   'concept': Concepts.economy,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829611262?h=93df4a5cb5',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'facebook',
   'credits': Credits.scott012021
  }, {
   'key': 51,
   'group': Tags.headquarters,
   'concept': Concepts.space,
   'city': Cities.berlin,
   'mediaType': MediaTypes.captionedVideo,
   'content': '829610562?h=17cbf76da7',
   'aspectRatio': 75,
   'caption': <Linkify> <a href="https://www.metrozones.info/headquarter/" target="_blank" rel="noreferrer">The Cuvry-Brache – from wasteland to Lieferando {Icons.linkIcon}</a> </Linkify>,
   'name': 'cuvry_brownfield',
   'credits': Credits.anne
  }, {
   'key': 52,
   'group': Tags.headquarters,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829608870?h=2d19825336',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'groundscraper',
   'credits': Credits.scott012021
  }, {
   'key': 53,
   'group': Tags.headquarters,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829609439?h=6d534d1037',
   'aspectRatio': 56.25,
   'caption': '',
   'name': '7000_googlers',
   'credits': Credits.scott012021
  }, {
   'key': 54,
   'group': Tags.imagination,
   'concept': Concepts.data,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829596918?h=b52d3a4251',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'kx_platform',
   'credits': Credits.scott012021
  }, {
   'key': 55,
   'group': Tags.imagination,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829601259?h=81bb23f64c',
   'aspectRatio': 62.5,
   'caption': '',
   'name': 'foundry_communities',
   'credits': Credits.gunes012021
  }, {
   'key': 56,
   'group': Tags.imagination,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829607317?h=d5d64b599e',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'steampunk',
   'credits': Credits.scott012021
  }, {
   'key': 57,
   'group': Tags.imagination,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829601764?h=9ce580f904',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'platforms_as_urban_infrastructure',
   'credits': Credits.scott012021
  }, {
   'key': 58,
   'group': Tags.new_work,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829593982?h=d1623a837f',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'regeneration_ethics',
   'credits': Credits.scott012021
  }, {
   'key': 59,
   'group': Tags.new_work,
   'concept': Concepts.space,
   'city': Cities.poznan,
   'mediaType': MediaTypes.video,
   'content': '829594931?h=4538eb25ab',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'mining_region',
   'credits': Credits.kuba122020
  }, {
   'key': 60,
   'group': Tags.new_work,
   'concept': Concepts.work,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829595255?h=9ecb15e518',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'chomsky',
   'credits': Credits.scott012021
  }, {
   'key': 61,
   'group': Tags.sharing,
   'concept': Concepts.commons,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.video,
   'content': '829580709?h=eabb37bdc4',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'bottom_up_initiatives',
   'credits': Credits.wouter112020
  }, {
   'key': 62,
   'group': Tags.past,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829590193?h=5c4f3a3a9b',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'wasteland',
   'credits': Credits.scott012021
  }, {
   'key': 63,
   'group': Tags.past,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829590524?h=1685369808',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'regent_canal',
   'credits': Credits.scott012021
  }, {
   'key': 64,
   'group': Tags.past,
   'concept': Concepts.space,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.video,
   'content': '829596364?h=d5f9960ad0',
   'aspectRatio': 54.17,
   'caption': '',
   'name': 'skyscraper',
   'credits': Credits.kuba122020  
  }, {
   'key': 65,
   'group': Tags.resistance,
   'concept': Concepts.space,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829586816?h=37c2383191',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'coal_drops_protests',
   'credits': Credits.scott012021
  }, {
   'key': 66,
   'group': Tags.resistance,
   'concept': Concepts.work,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.video,
   'content': '829586404?h=fd1ff4c475',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'poblenou',
   'credits': Credits.wouter112020  
  }, {
   'key': 67,
   'group': Tags.resistance,
   'concept': Concepts.work,
   'city': Cities.poznan,
   'mediaType': MediaTypes.video,
   'content': '829585551?h=ad8eebd7c8',
   'aspectRatio': 54.17,
   'caption': '',
   'name': 'antifascist_workers_union',
   'credits': Credits.kuba122020
  }, {
   'key': 68,
   'group': Tags.resistance,
   'concept': Concepts.work,
   'city': Cities.poznan,
   'mediaType': MediaTypes.video,
   'content': '829584961?h=a1a982a69f',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'workers_network',
   'credits': Credits.tytus012021  
  }, {
   'key': 69,
   'group': Tags.resistance,
   'concept': Concepts.governance,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.video,
   'content': '829583999?h=8e9d5cde17',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'urban_activists',
   'credits': Credits.kuba122020
  }, {
   'key': 70,
   'group': Tags.speculation,
   'concept': Concepts.governance,
   'city': Cities.london,
   'mediaType': MediaTypes.video,
   'content': '829577222?h=6298d18e53',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'flipping_real_estate',
   'credits': Credits.scott012021
  }, {
   'key': 71,
   'group': Tags.speculation,
   'concept': Concepts.space,
   'city': Cities.barcelona,
   'mediaType': MediaTypes.video,
   'content': '829576193?h=1e00e3244e',
   'aspectRatio': 56.25,
   'caption': '',
   'name': 'transforming_neighborhoods',
   'credits': Credits.wouter112020
  }, {
   'key': 72,
   'group': Tags.migration,
   'concept': Concepts.work,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.audio,
   'content': 'kwildner/migration-work-warz-kuba-migrant-workers',
   'aspectRatio': 1,
   'caption': '',
   'name': 'migrant_workers',
   'credits': Credits.kuba122020
  }, {
   'key': 73,
   'group': Tags.past,
   'concept': Concepts.space,
   'city': Cities.warzawa,
   'mediaType': MediaTypes.audio,
   'content': 'user-519943150/past-space-warz-exjewish-quarter?si=c9ffa0d1888543f0a642ca3600c108cd&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',
   'aspectRatio': 1,
   'caption': '',
   'name': 'elastic_offices',
   'credits': Credits.kuba122020
  }, {
   'key': 74,
   'group': Tags.resistance,
   'concept': Concepts.space,
   'city': Cities.berlin,
   'mediaType': MediaTypes.image,
   'content': '/img/74/01.jpg',
   'aspectRatio': 0,
   'caption': 'Protest of the activist network Berlin vs. Amazon against the construction of the so-called Amazon Tower at Warschauer Brücke in March 2022. The 140-metre-high tower was completed at the end of 2022.',
   'name': 'berlin_vs_amazon',
   'credits': Credits.tbd
  }
];

export default data;