class Node {
  constructor(key, group, concept, city, mediaType, position, content, aspectRatio, caption, credits, name) {
    this.key = key;
    this.id = key;
    this.group = group;
    this.concept = concept;
    this.city = city;
    this.mediaType = mediaType;
    this.position = position;    
    this.content = content;
    this.aspectRatio = aspectRatio;
    this.caption = caption;
    this.credits = credits;
    this.name = name;
  }
}

export default Node;