class GraphNode {
    constructor(node) {
      this.id = node.key;
      this.name = node.name;
      this.val = 10;

      this.group = node.group;
      this.concept = node.concept;
      this.city = node.city;
      this.mediaType = node.mediaType;
      this.position = node.position;    
      this.content = node.content;
      this.caption = node.caption;
      this.credits = node.credits;
    }
  }
  
  export default GraphNode;