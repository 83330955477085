import { useParams } from 'react-router-dom';
import AbstractMedia from '../../data/mediatypes/AbstractMedia';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import './dialog.css';

export default function Dialog(props) {

  let { dialogUrl } = useParams();

  const node = props.data.find(node => node.name === dialogUrl);
  
  return (
    <div className='dialog'>
      <Modal open={ true }
             center
             focusTrapped={ true }
             showCloseIcon={ false }
             onClose={ () => props.onCloseModal(false) }
             closeOnOverlayClick={ true }
             closeOnEsc={ true }>            
        <AbstractMedia element={ node }
                       position={ props.position }
                       onAddLockerItem={ props.onAddLockerItem }
                       onCloseModal={ () => props.onCloseModal(false) }/>
      </Modal> 
    </div>
  );  
}