import Dexie from 'dexie';

export const LockerDB = new Dexie('locker');
LockerDB.version(1).stores({
  lockerItems: '++key, image, name, position'
});

export const VisitedDB = new Dexie('visited');
VisitedDB.version(1).stores({
  visitedNodes: 'id'
});