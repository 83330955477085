import './text.css';

export default function Text(props) {

  return(        
    <div className='holder'>
      <div className='quote'>
        { props.content }
      </div>
    </div>
  ) 
}